<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-card class="rounded-xl overflow-hidden" :disabled="loading">
    <v-toolbar class="primary" tag="div" flat dark tile>
      <div class="text-h6">{{ $t("smslist") }}</div></v-toolbar
    >
    <template>
      <v-data-table
        :headers="headers"
        :items="sms"
        item-key="name"
        class="elevation-1"
      ></v-data-table>
    </template>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "From",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "To", align: "start", sortable: false, value: "calories" },
        {
          text: "Direction",
          align: "start",
          sortable: false,
          value: "calories",
        },
        { text: "Message", align: "start", sortable: false, value: "calories" },
        {
          text: "Created at",
          align: "start",
          sortable: false,
          value: "calories",
        },
        {
          text: "Attachments",
          align: "start",
          sortable: false,
          value: "calories",
        },
        { text: "Actions", align: "start", sortable: false, value: "calories" },
      ],
      sms: [],
    };
  },
};
</script>
<style lang=""></style>
